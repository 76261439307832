const currentDate = new Date();
function CurrentDate() {


    return(
        <>
        <span className=" topHeaders align-middle flex flex-wrap w-full text-center md:w-1/3 lg:w-1/4 justify-center min-w-fit">            
            <p className="currentDate justify-center font-Garamond text-xl text-sb-dark-grey first-letter:font-Cinzel first-letter:text-2xl" > 
                { currentDate.toDateString() } 
            </p>
        </span>       
        </>
    )
}

export { CurrentDate }