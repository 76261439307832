import axios from "axios";
import { useState } from "react";
import { BarLoader } from "react-spinners";
import { Toast } from "./Toast";
import { formInputEvent } from "../analytics";


function EmailInput(props) {
    
    const [ email, setEmail ] = useState('');
    const [loadingInProgress, setLoading] = useState(false);
    const [successState, setSuccessState] = useState(false);

    const catchEmailChange = (event) => setEmail(event.target.value);
    const changeLoadingState = () => setLoading(true);
    const changeSuccessState = (bool) => setSuccessState(bool);

    const config = {
        'Access-Control-Allow-Origin':'*'
    }
    
    const saveEmail = () => {
        formInputEvent('Email CTA Clicked', {'emailValue': email});
        axios.post(`${process.env.REACT_APP_API_URL}new-email`,
        {
            email: email,
            date: new Date()
        }, config ).catch(
            (error)=>{
                // console.log(error)
            }
        );
        // console.log(axios.get('http://localhost:8000'), config)
        changeLoadingState();
        setTimeout( ()=>{
            setLoading(false);
            changeSuccessState(true);
            setTimeout(() => {
                changeSuccessState(false);
            }, 3500);
        }, 2500 )
    }

    const preventRefresh = (event) => {
        event.preventDefault()
    }
    
    return (
        <>
        <h2 className="text-center font-Garamond text-3xl sm:text-3xl md:text-4xl my-7 w-[70%] mx-auto text-sb-dark-grey" >
            Don't miss Skilled Read's launch. <br/>
            <b>Leave your email and we'll let you know!</b>
        </h2>
        <div className="revue-embed">
            <form className="revue-form-container" id="revue-form" name="revue-form" onSubmit={preventRefresh} target="_blank">
                <div className="revue-form-group">
                    {/* <label className="revue-form-label" for="member_email">Email address</label> */}
                    <input 
                    onChange={catchEmailChange}
                    onClick={ ()=>formInputEvent('Email Input Clicked') }
                    className="revue-form-field block w-3/4 2xl:w-1/2 mx-auto text-grey-1500 p-3 rounded border-y-4 border-solid border-grey-700" 
                    placeholder="zhivago@yuriatin.com" type="email" name="member[email]" id="member_email"
                    />
                </div>
                <div className="revue-form-actions">
                    <input onClick={saveEmail} className="font-Garamond revue-subscribe-button block w-30 p-3 mx-auto my-5 text-lg rounded text-sb-dark-grey cursor-pointer bg-green-500 shadow-xl shadow-green-300 btn" 
                    type="submit" value="Stay up to date" name="member[subscribe]" id="member_submit"                        
                    />
                <div className="w-[40%] m-auto bg-[rgba(255,255,255,0)]" >{loadingInProgress && <BarLoader size='5' height='5' margin='auto' width='100%' speedMultiplier='1' color='#86efac' /> }</div>
                { successState && <Toast message="Email saved succesfully" /> }
                </div>
                <div className="revue-form-footer w-fit mx-auto text-gray-400">By subscribing, you agree with Revue's <a target="_blank" rel="noreferrer" href="https://www.getrevue.co/terms" className='text-gray-500 underline'>Terms of Service</a> and <a className='text-gray-500 underline' target="_blank" rel="noreferrer" href="https://www.getrevue.co/privacy">Privacy Policy</a>.</div>
            </form>
        </div>
        </>

    );

};

export { EmailInput }