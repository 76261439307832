import axios from "axios";
import { useState } from "react";
import { BarLoader } from "react-spinners";
import { Toast } from "./Toast";
import { formInputEvent } from "../analytics";



function SuggestionsForm(props) {
    
    const [ sugg, setSugg ] = useState('');
    const [loadingInProgress, setLoading] = useState(false);
    const [successState, setSuccessState] = useState(false);
    
    const catchValueChange = (event) => setSugg(event.target.value)

    const changeLoadingState = () => setLoading(true);
    const changeSuccessState = (bool) => setSuccessState(bool);

    const config = {
        'Access-Control-Allow-Origin':'*'
    }
    
    const saveSugg = () => {
        formInputEvent('Suggestions CTA clicked', {"suggestionValue": sugg,});
        axios.post(`${process.env.REACT_APP_API_URL}new-suggestion`,
        {
            body: sugg,
            date: new Date()
        }, config ).catch(
            (error)=>{
                // console.log(error)        
            }
        )
        // console.log(axios.get('http://localhost:8000'), config)
        console.log(sugg);
        changeLoadingState();
        setTimeout( ()=>{
            setLoading(false);
            changeSuccessState(true);
            setTimeout(() => {
                changeSuccessState(false);
            }, 3500);
        }, 2500 )
    };

    const preventRefresh = (event) => {
        event.preventDefault()
    }

    return(
        <>
        <div className="md:block w-[90%] m-auto p-4" >
            <p className="flex-1 block md:my-5 font-Garamond text-xl lg:text-3xl xl:text-3xl m-2 first-letter:font-Cinzel first-letter:text-5xl text-justify w-[90%] 2xl:w-[35%] mx-auto">
            Skilled Books is currently on development, so if you have a specific <b>feature you want to ask for</b>, go ahead and leave a message.
            </p>
            <form className="flex-1 w-[90%] m-auto p-4" action="" method="post" onSubmit={preventRefresh}>
                <textarea 
                    className="block w-full lg:w-[65%] m-auto h-72 p-3 rounded font-Garamond text-xl first-letter:font-Cinzel first-letter:text-3xl border-y-4 border-solid border-grey-700 " id="" 
                    placeholder="Your suggestion or request..." type='text' name="Suggestion"
                    value={sugg}
                    onChange={catchValueChange}
                    onClick={ ()=>formInputEvent('Suggestions textarea clicked') }
                >
                </textarea>
                <button onClick={saveSugg} className="font-Garamond w-[35%] sm:w-[25%] block w-30 p-3 mx-auto my-5 text-lg rounded text-gray-800 cursor-pointer bg-green-500 shadow-xl shadow-green-300 btn"> Submit </button>
                <div className="w-[35%] m-auto bg-[rgba(255,255,255,0)]" >{loadingInProgress && <BarLoader size='5' height='5' margin='auto' width='100%' speedMultiplier='1' color='#86efac' /> }</div>
                { successState && <Toast message="Suggestion submitted" /> }
            </form>
        </div>
        </>
    );
    
};

export { SuggestionsForm }