function Toast(props) {
    return (
        <>
        <div className="rounded bg-sb-pink w-fit h-fit m-auto toast shadow-xl shadow-pink-100" >
            <p className="text-[#242424] text-center p-3 font-Garamond text-xl" >
                {props.message}
            </p>
        </div>
        </>
    );
}

export { Toast }