import { linkEvent } from "../analytics";

function Footer(props) {
    return(
        <>
            <h2 className='font-Garamond text-center'>
                By 
                <a
                onClick={()=>linkEvent('Footer Link Clicked')}
                className='font-Cinzel underline' href='https://carlos-z-bent.com' target='_blank' rel='noreferrer'>
                    Carlos Z. Bent
                </a>
            </h2>
        </>
    );
};

export { Footer }