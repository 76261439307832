
/* component that groups the name text and the logo image */
function SrSign() {

    return (
    <>
    <span className='topHeaders align-middle w-full text-center md:w-1/3 lg:w-1/6 justify-between min-w-fit' >
        {/*<img src={logo} alt='skilledreads logo' className='max-w-[20%] min-w-[40%] sm:min-w-[20%] sm:max-w-[15%] md:max-w-[40%] md:max-h-[75%] md:min-w-[40%] lg:max-h-fit lg:min-w-[60%] xl:min-w-[50%] sm:block hidden flex-1 m-1' />*/}
        <h1 className='flex-1 text-sb-dark-grey font-Garamond text-xl first-letter:font-Cinzel first-letter:text-2xl leading-normal m-auto mb-2 font-medium align-middle justify-center' > Read your way to success </h1>
    </span>
    </>
    )
}

export {SrSign}