import overview_image from '../images/overview-image.jpg'

function SrOverview (props) {
    return (
        <>
            <div className='h-[90%] w-[85%] lg:w-full xl:w-[90%] m-auto flex p-3 justify-between' >
                <img alt='illustration of a person reading' src={overview_image}
                className='flex-1 hidden lg:block max-w-[50%] lg:min-w-[50%] lg:h-1/3 xl:h-1/2 2xl:hidden m-auto'
                ></img>
                <div className='flex-1 p-2' >
                    <h1 className='font-Cinzel text-2xl md:text-4xl 2xl:text-5xl text-center text-sb-dark-grey'>
                        What is Skilled Reads?
                    </h1>
                    <p className=' font-Garamond text-xl lg:text-3xl xl:text-3xl m-2 first-letter:font-Cinzel first-letter:text-5xl text-justify block 2xl:w-[60%] w-[90%] mx-auto text-sb-dark-grey' >
                        Skilled Reads is a platform where we can get together and discuss books, articles and blog posts that will help us grow on the startup ecosystem with each other.
                        <br/><br/>
                        It's rough and competitive out there, and there are a lot of technical stuff we don't know, as well as simple yet powerful concepts we should know from the beginning. 
                        So simply write a review on the platform and everyone will learn from it, and browse other users reviews to enrich your portfolio of resources.
                        <br/><br/>
                        Oh, don't forget it all goes to what will be the greatest database of startup related reading resources on the whole web, and you can be among the leaders of that adventure.
                    </p>
                </div>
            </div>
        </>
    );
}

export {SrOverview}