import './App.css';

import { SrSign } from './components/SrSign';
import { CurrentDate } from './components/CurrentDate'
import { HeroText } from './components/HeroText'
import { EmailInput } from './components/EmailInput'
import { SrOverview } from './components/SrOverview'
import { SrMainPoints } from './components/SrMainPoints'
import { ReviewsForm } from './components/ReviewsForm'
import { SuggestionsForm } from './components/SuggestionsForm'
import { Footer } from './components/Footer'
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';


const mixpanelWebsiteVisit = () => {
  const mixpanel_token = process.env.REACT_APP_MIXPANEL_TOKEN
  mixpanel.init(mixpanel_token)
  mixpanel.track('Website visit')
};


function App () {
  useEffect(()=>{
    mixpanelWebsiteVisit()
  }, [])

  return (
    <>
    {/*main 1st section container*/}
    <div className='m-auto mb-5 w-[75%] min-w-[40%] mt-2 max-h-fit p-2' id='section1Container' >
      <div className='topHeadersContainer border-y-2 border-y-sb-dark-grey p-4 flex flex-wrap-reverse justify-between w-full lg:justify-evenly xl:justify-between xl:w-[85%] xl:m-auto 2xl:w-[75%] h-1/4' id='section1TopSubcontainer'>
        <SrSign className='flex-1' ></SrSign>
        <CurrentDate className='flex-1'></CurrentDate>
      </div>
      <div className='firstSubContainer p-3 mt-5 ' id='section1BottomSubcontainer' >
        <HeroText />
      </div>
    </div>
    
    {/* main 2nd section container */}
    <div className='sm:flex justify-between w-[95%] mx-auto'>
      <div className='bg-sr-bg-grey p-5 my-5 mx-auto w-[95%] rounded-md flex flex-col' id='MainPointsSectionContainer'>
        <SrMainPoints />
      </div>
      <div className='emailInputContainer bg-sr-bg-grey border-2 border-sb-pink p-5 my-5 mx-auto w-[95%] rounded-md shadow ' 
      id='EmailSectionContainer'>
        <EmailInput />
      </div>
    </div>
    <div className='bg-sr-bg-grey p-5 my-5 mx-auto w-[95%] rounded-md flex flex-col' id='OverviewSection3Container'>
      <SrOverview/>
    </div>
    <div className='bg-sr-bg-grey p-5 my-5 mx-auto w-[95%] rounded-md flex flex-col' id='ReviewsFormSectionContainer'>
      <ReviewsForm/>
    </div>
    <div className='bg-sr-bg-grey p-5 my-5 mx-auto w-[95%] rounded-md flex flex-col' id='SuggestionsFormSectionContainer'>
      <SuggestionsForm/>
    </div>
    <div className='bg-gradient-to-r from-sb-pink to-[#b4d1e1] p-5 my-5 mx-auto w-full mb-0 rounded-md flex flex-col' 
    id='SuggestionsFormSectionContainer'>
      <Footer/>
    </div>
    </>
  );
  }
export default App;
