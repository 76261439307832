import mixpanel from "mixpanel-browser";

const mixpanel_token = process.env.REACT_APP_MIXPANEL_TOKEN
mixpanel.init(mixpanel_token)

export const formInputEvent = (event_name, data={} ) => {
    mixpanel.track(event_name, data)
  };

export const linkEvent = (event_name) => {
  console.log(event_name);
  mixpanel.track(event_name)
}