
/* component that groups the main hero text and the secondary hero text */
function HeroText () {
    return (
        <>
            <h1 className='font-Cinzel text-sb-dark-grey text-center text-3xl sm:text-5xl md:text-6xl mb-5' >
                Skilled Reads
            </h1>
            <p className='font-Garamond text-sb-dark-grey text-center text-2xl md:text-3xl block w-[85%] md:w-[55%] m-auto' >
                Meet resourceful entrepreneurs that succeed by reading eagerly.
            </p>
        </>
    );
};

export { HeroText }