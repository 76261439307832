import axios from "axios";
import { useState } from "react";
import { BarLoader } from "react-spinners";
import { Toast } from "./Toast";
import { formInputEvent } from "../analytics";


function ReviewsForm (props) {
    
    const [ author, setAuthor ] = useState('');
    const [ postName, setPostName ] = useState('');
    const [ body, setBody ] = useState('');
    const [loadingInProgress, setLoading] = useState(false);
    const [successState, setSuccessState] = useState(false);
    
    const catchAuthorChange = (event) => setAuthor(event.target.value);
    const catchPostNameChange = (event) => setPostName(event.target.value);
    const catchBodyChange = (event) => setBody(event.target.value);

    const changeLoadingState = () => setLoading(true);
    const changeSuccessState = (bool) => setSuccessState(bool);
    
    const config = {
        'Access-Control-Allow-Origin':'*'
    }
    
    const savePost = () => {
        formInputEvent('Reviews CTA Clicked', 
            {
                "reviewAuthor":author,
                "reviewName":postName,
                "reviewLength":body.length,
                "reviewPreview":body.slice(0, 30)
            }
        );
        axios.post(`${process.env.REACT_APP_API_URL}new-post`,
        {
            author_name: author,
            post_name: postName,
            body: body,
            date: new Date()
        }, config ).catch(
            (error)=>{
                // console.log(error)
            }
        )
        // console.log(axios.get('http://localhost:8000'), config)
        // console.log(`Author: ${author}, Name: ${postName}, Body: ${body}, Date: ${new Date()}`);
        changeLoadingState();
        setTimeout( ()=>{
            setLoading(false);
            changeSuccessState(true);
            setTimeout(() => {
                changeSuccessState(false);
            }, 3500);
        }, 2500 )
    }

    const preventRefresh = (event) => {
        event.preventDefault()
    }

    return (
        <>
        <div className="md:flex md:justify-between w-[90%] m-auto p-4" >
            <p className="flex-1 block md:mt-32 font-Garamond text-xl lg:text-3xl xl:text-3xl m-2 first-letter:font-Cinzel first-letter:text-5xl text-justify w-[90%] mx-auto">
                You can <b>have your first review published</b> on Skilled Reads <b>at launch</b> by writing it here!
                <br/><br/>
                Once you have your official account you can redeem that piece.
            </p>
            <form className="flex-1 w-[90%] m-auto p-4" action="" method="post" onSubmit={preventRefresh}>
                <input
                value={author} onChange={catchAuthorChange} 
                className="block w-full lg:w-[65%] mx-auto p-3 rounded font-Garamond text-xl border-y-4 border-solid border-grey-700" 
                id="" placeholder="Your name..." type='name' name="Name" ></input>
                <br/>
                <input 
                value={postName} onChange={catchPostNameChange} 
                className="block w-full lg:w-[65%] m-auto p-3 rounded font-Garamond text-xl border-y-4 border-solid border-grey-700" 
                id="" placeholder="The name of your post..." type='name' name="ArticleName"></input>
                <br/>
                <textarea 
                value={body} onChange={catchBodyChange} onClick={ ()=>formInputEvent('Reviews textarea clicked') }
                className="block w-full lg:w-[65%] m-auto h-72 p-3 rounded font-Garamond text-xl first-letter:font-Cinzel first-letter:text-3xl border-y-4 border-solid border-grey-700" 
                id="" placeholder="Your recommendation to other readers..." type='text' name="Article"></textarea>
                <button 
                onClick={savePost} 
                className="font-Garamond w-[35%] sm:w-[25%] block w-30 p-3 mx-auto my-5 text-lg rounded text-gray-800 cursor-pointer bg-green-500 shadow-xl shadow-green-300 btn"
                > 
                Post
                </button>
                <div className="w-[40%] m-auto bg-[rgba(255,255,255,0)]" >{loadingInProgress && <BarLoader size='5' height='5' margin='auto' width='100%' speedMultiplier='1' color='#86efac' /> }</div>
                { successState && <Toast message="Post submitted" /> }
            </form>
        </div>
        </>
    );
};

export { ReviewsForm }