
function SrMainPoints (props) {
	return (
		<>
		<h1 className='font-Cinzel text-2xl md:text-4xl 2xl:text-5xl my-2 text-center text-sb-dark-grey'>
            With Skilled Reads you can:
        </h1>
        <div className="grid grid-cols-2 grid-rows-2 gap-8 p-4 ">
        	<p className='font-Garamond text-sb-dark-grey text-center text-xl md:text-3xl md:first-letter:text-5xl first-letter:font-Cinzel'>Share your <b>passion for learning</b> through reading with your fellow founders</p>
        	<p className='font-Garamond text-sb-dark-grey text-center text-xl md:text-3xl md:first-letter:text-5xl first-letter:font-Cinzel'>Contribute to the greatest repository of startup related <b>growth material</b></p>
        	<p className='font-Garamond text-sb-dark-grey text-center text-xl md:text-3xl md:first-letter:text-5xl first-letter:font-Cinzel'>Stay up to date with the best <b>resources</b> for entrepreneurs</p>
        	<p className='font-Garamond text-sb-dark-grey text-center text-xl md:text-3xl md:first-letter:text-5xl first-letter:font-Cinzel'>Get in touch with a community of <b>resourceful</b> startup founders</p>
        </div>
        </>
		);
};

export { SrMainPoints }